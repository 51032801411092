require.config({
    // Disable script timeouts
    waitSeconds: 0,
    paths: {
        'jquery': '../../../node_modules/jquery/dist/jquery',
        'modernizr': 'lib/modernizr-custom',
        'backbone': '../../../node_modules/backbone/backbone',
        'backbone-marionette': '../../../node_modules/backbone.marionette/lib/core/amd/backbone.marionette',
        'backbone.wreqr': '../../../node_modules/backbone.wreqr/lib/backbone.wreqr',
        'backbone.babysitter': '../../../node_modules/backbone.babysitter/lib/backbone.babysitter',
        'underscore': '../../../node_modules/underscore/underscore',
        'react': '../../../node_modules/react/dist/react',
        'prop-types': '../../../node_modules/prop-types/prop-types',
        'qrcodejs': 'lib/qrcode',
        'react-dom': '../../../node_modules/react-dom/dist/react-dom',
        'react-redux': '../../../node_modules/react-redux/dist/react-redux',
        'redux': '../../../node_modules/redux/dist/redux',
        'handlebars': '../../../node_modules/handlebars/dist/handlebars.runtime',
        'jquery.slick': '../../../node_modules/slick-carousel/slick/slick',
        'loglevel': '../../../node_modules/loglevel/dist/loglevel',
        'moment': '../../../node_modules/moment/moment',
        'raven': '../../../node_modules/raven-js/dist/raven.min',
        'dompurify': '../../../node_modules/dompurify/dist/purify',
        'jquery.shave': '../../../node_modules/shave/dist/jquery.shave',
        'jquery.fitvids': 'vendor/jquery/jquery.fitvids',

        // allows, e.g., require('bootstrap/dropdown')
        'bootstrap': '../../../node_modules/bootstrap/js',

        // Disqus Next-Core - shared Disqus front end js components
        'core': '../../../next-core/core',
        'core/config': 'core/config',
        'core/templates': 'templates/next-core',
        'core/views/react': 'core/views/react',

        // External sources
        // The optimizer will automatically skip network paths
        'disqus.sdk': 'https://c.disquscdn.com/next/current/embed/sdk',
        'remote/config': 'https://disqus.com/next/config',
        'translations': 'empty:',

        // All modules are namespaced under 'home' for code
        // sharing purposes.
        'home': '.',
    },
    packages: [{
        name: 'stance',
        location: '../../../next-core/core/stance',
    }],
    shim: {
        'modernizr': {
            exports: 'Modernizr',
        },
        'underscore': {
            exports: '_',
        },
        'qrcodejs': {
            exports: 'QRCode',
        },
        'handlebars': {
            exports: 'Handlebars',
        },
        'jquery.fitvids': {
            deps: ['jquery'],
        },
        'jquery.shave': {
            deps: ['jquery'],
        },
        'remote/config': {
            exports: 'DISQUS.config',
        },

        'bootstrap/dropdown': {
            deps: ['jquery'],
        },

        'bootstrap/tooltip': {
            deps: ['jquery'],
        },

        'bootstrap/modal': {
            deps: ['jquery'],
        },

        'bootstrap/collapse': {
            deps: ['jquery'],
        },
    },
});

